import React from "react";

// Component imports
import Layout from "../components/common/layout";
import Construction from "../components/common/Construction";

// Image imports

// CSS imports

export default ({ data }) => {
  return (
      <Layout fixedFooter={true}>
          <Construction title="Solutions"/>
      </Layout>
  );
};